<template>
  <b-card>
    <b-row>
      <b-breadcrumb class="mb-1">
        <b-breadcrumb-item :to="{ name: 'app-scoutsuite' }">
          Scans
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :to="{
            name: 'app-scoutsuite-list',
            params: { id: securityanalysisData.securityanalysisProjectId },
          }"
        >
          {{
            securityanalysisData.securityanalysisProjectName
              .charAt(0)
              .toUpperCase() +
            securityanalysisData.securityanalysisProjectName.slice(1)
          }}
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :to="{
            name: 'apps-scoutsuite-view',
            params: { id: securityanalysisDatabaseNameIndex },
          }"
        >
          {{ serviceName }} Detail Report
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ securityanalysisRoleName }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </b-row>

    <div v-for="(value, index) in servicesInfromation">
      <b-row class="m-1">
        <b-col cols="12" lg="8">
          <span v-if="value.rationale">
            <b class="headline"> Description:</b><br />
            <div class="headline" v-html="value.rationale"></div>
            <br
          /></span>
          <span v-else> No description available.</span>

          <span v-if="value.remediation">
            <b class="headline"> Remediation:</b><br />
            <div class="headline" v-html="value.remediation"></div>
          </span>

          <span v-if="value.compliance">
            <b class="headline">Compliance:</b><br />
            <ul v-for="(data, index) in value.compliance">
              <li>
                {{ data.name }} version {{ data.version }}, reference
                {{ data.reference }}
              </li>
            </ul>
          </span>

          <span v-if="value.references">
            <b class="headline">References:</b><br />
            <ul v-for="(data, index) in value.references">
              <li>
                <a :href="data" target="_blank">{{ data }}</a>
              </li>
            </ul>
          </span>
        </b-col>

        <b-col class="d-flex justify-content-end">
          <ul>
            <li class="mr-1">
              {{ value.dashboard_name }} checked:
              {{ value.checked_items }}
            </li>
            <li>
              {{ value.dashboard_name }} flagged:
              {{ value.flagged_items }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>

    <div v-for="(data, index) in projectsData">
      <!-- bigquery -->
      <div v-if="databaseName == 'datasets'">
        <span v-for="(value, key) in data.datasets">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>
          <div>
            <h4 class="headline">Information</h4>
            <div class="px-2">
              <span class="title">Name:</span>
              <span class="description">{{
                value.name ? value.name : "None"
              }}</span>
            </div>
            <div class="px-2">
              <span class="title">Location:</span>
              <span class="description">{{
                value.location ? value.location : "None"
              }}</span>
            </div>
            <div class="px-2">
              <span class="title"> Creation Time:</span>
              <span class="description">
                {{ value.creation_time ? value.creation_time : "None" }}
              </span>
            </div>
            <div class="px-2">
              <span class="title"> Last Modified Time:</span>
              <span class="description">
                {{
                  value.last_modified_time ? value.last_modified_time : "None"
                }}
              </span>
            </div>
            <div class="px-2">
              <span class="title"> Default Encryption Key:</span>
              <span class="description">
                {{
                  value.default_encryption_configuration
                    ? value.default_encryption_configuration
                    : "None"
                }}
              </span>
            </div>
          </div>
          <hr />
          <div>
            <h4 class="headline">IAM Permissions</h4>
            <div v-if="value.bindings">
              <ul v-for="(value, key) in value.bindings">
                <li>
                  {{ key }}
                  <ul v-for="(value, key) in value">
                    <li>{{ value.type }}:{{ value.member }}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <!-- cloudmemorystore -->
      <div v-if="databaseName == 'redis_instances'">
        <span v-for="(value, key) in data.redis_instances">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>
          <div>
            <h4 class="headline">Information</h4>
            <div class="px-2">
              <span class="title"> Location: </span>
              <span class="description">
                {{ value.location ? value.location : "None" }}
              </span>
            </div>
            <div class="px-2">
              <span class="title"> Redis Version:</span>
              <span class="description">
                {{ value.redis_version ? value.redis_version : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Transit Encryption Mode:</span>
              <span class="description">
                {{ value.ssl_required ? value.ssl_required : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Auth Enabled:</span>
              <span class="description">
                {{ value.auth_enabled ? value.auth_enabled : "None" }}</span
              >
            </div>
          </div>
        </span>
      </div>

      <!-- cloudsql -->
      <div
        v-if="
          databaseName == 'instances' || databaseName == 'authorized_networks'
        "
      >
        <span v-for="(value, key) in data.instances">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>
          <div>
            <h4 class="headline">Information</h4>
            <div class="px-2">
              <span class="title"> Project ID: </span>
              <span class="description">
                {{ value.project_id ? value.project_id : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Automatic Backups:</span>
              <span class="description">
                {{
                  value.automatic_backup_enabled
                    ? value.automatic_backup_enabled
                    : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Last Backup:</span>
              <span class="description">
                {{
                  value.last_backup_timestamp
                    ? value.last_backup_timestamp
                    : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Logs: </span>
              <span class="description">
                {{ value.log_enabled ? value.log_enabled : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> SSL Required:</span>
              <span class="description">
                {{ value.ssl_required ? value.ssl_required : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Public IP Address:</span>
              <span class="description">
                {{ value.public_ip ? value.public_ip : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Private IP Address:</span>
              <span class="description">
                {{ value.private_ip ? value.private_ip : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Local Infile Flag is Off:</span>
              <span class="description">
                {{
                  value.local_infile_off ? value.local_infile_off : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title">
                Cross db Ownership Chaining Flag is Off:</span
              >
              <span class="description">
                {{
                  value.cross_db_ownership_chaining_off
                    ? value.cross_db_ownership_chaining_off
                    : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title">
                Contained Database Authentication Flag is Off:</span
              >
              <span class="description">
                {{
                  value.contained_database_authentication_off
                    ? value.contained_database_authentication_off
                    : "None"
                }}</span
              >
            </div>

            <div class="px-2">
              <span class="title"> Log Checkpoints Flag is On:</span>
              <span class="description">
                {{
                  value.log_checkpoints_on ? value.log_checkpoints_on : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Log Connections Flag is On:</span>
              <span class="description">
                {{
                  value.log_connections_on ? value.log_connections_on : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Log Disconnections Flag is On:</span>
              <span class="description">
                {{
                  value.log_disconnections_on
                    ? value.log_disconnections_on
                    : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Log Lock Waits Flag is On:</span>
              <span class="description">
                {{
                  value.log_lock_waits_on ? value.log_lock_waits_on : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title">
                Log Min Messages Flag set Appropriately:</span
              >
              <span class="description">
                {{
                  value.log_min_messages ? value.log_min_messages : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Log Temp Files Flag set to 0:</span>
              <span class="description">
                {{
                  value.log_temp_files_0 ? value.log_temp_files_0 : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title">
                Log Min Duration Statement Flag set to -1:
              </span>
              <span class="description">
                {{
                  value.log_min_duration_statement_ - 1
                    ? value.log_min_duration_statement_ - 1
                    : "None"
                }}
              </span>
            </div>

            <div class="px-2">
              Authorized Networks:
              <div v-if="value.authorized_networks.length !== 0">
                <ul v-for="(value, key) in value.authorized_networks">
                  <li>
                    {{ value.value }}
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>

            <div class="px-2">
              Users:
              <div v-if="Object.keys(value.users).length == 0">
                <ul>
                  <li>None</li>
                </ul>
              </div>
              <div v-else>
                <ul v-for="(value, key) in value.users">
                  <li>
                    {{ value.name }}
                    <span v-if="value.host">(host: {{ value.host }})</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </span>
      </div>

      <!-- cloudstorage -->
      <div v-if="databaseName == 'buckets'">
        <span v-for="(value, key) in data.buckets">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project_id ? value.project_id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Creation Date:</span>
                <span class="description">
                  {{ value.creation_date ? value.creation_date : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Location: </span>
                <span class="description">
                  {{ value.location ? value.location : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Storage Class:</span>
                <span class="description">
                  {{ value.storage_class ? value.storage_class : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Logging:</span>
                <span class="description">
                  {{
                    value.logging_enabled ? value.logging_enabled : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Versioning:</span>
                <span class="description">
                  {{
                    value.versioning_enabled ? value.versioning_enabled : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Public Access Prevention:</span>
                <span class="description">
                  {{
                    value.public_access_prevention
                      ? value.public_access_prevention
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Uniform Bucket-Level Access:</span>
                <span class="description">
                  {{
                    value.uniform_bucket_level_access
                      ? value.uniform_bucket_level_access
                      : "None"
                  }}</span
                >
              </div>
            </div>
          </div>

          <hr />
          <div>
            <h4 class="headline">IAM Permissions</h4>
            <div v-if="Object.keys(value.member_bindings).length !== 0">
              <ul v-for="(value, key) in value.member_bindings">
                <li>
                  {{ key }}
                  <ul v-for="(value, key) in value">
                    <li>{{ value }}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>

          <hr />
          <div>
            <h4 class="headline">ACL Permissions</h4>
            <div v-if="value.acls.length !== 0">
              <ul v-for="(value, key) in value.acls">
                <li>
                  {{ value.entity }}
                  <ul>
                    <li>{{ value.role }}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>

          <hr />
          <div>
            <h4 class="headline">Default Object ACL Permissions</h4>
            <div v-if="value.default_object_acl.length !== 0">
              <ul v-for="(value, key) in value.default_object_acl">
                <li>
                  {{ value.entity }}
                  <ul>
                    <li>{{ value.role }}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <!-- computeengine -->
      <div
        v-if="
          databaseName == 'firewall_rule_elements' ||
          databaseName == 'firewall_rule' ||
          databaseName == 'firewall_rules'
        "
      >
        <span v-for="(value, key) in data.firewalls">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Firewall name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project_id ? value.project_id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Description:</span>
                <span class="description">
                  {{ value.description ? value.description : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Disabled: </span>
                <span class="description">
                  {{ value.disabled ? value.disabled : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Network: </span>
                <span class="description">
                  {{ value.network_id ? value.network_id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Creation Date:</span>
                <span class="description">
                  {{
                    value.creation_timestamp ? value.creation_timestamp : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Priority: </span>
                <span class="description">
                  {{ value.priority ? value.priority : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Logs: </span>
                <span class="description">
                  {{ value.logs ? value.logs : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Configuration</h4>
            <div class="px-2">
              <span class="title"> Direction: </span>
              <span class="description">
                {{ value.direction ? value.direction : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Action: </span>
              <span class="description">
                {{ value.action ? value.action : "None" }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Source Ranges:</span>
              <div v-if="value.source_ranges.length !== 0">
                <ul
                  v-for="(
                    sourceRangesValue, sourceRangesKey
                  ) in value.source_ranges"
                >
                  <li>
                    {{ sourceRangesValue }}
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>

            <div class="px-2">
              <span class="title"> Destination Ranges:</span>
              <div v-if="value.destination_ranges.length !== 0">
                <ul
                  v-for="(
                    destinationRangesValue, destinationRangesKey
                  ) in value.destination_ranges"
                >
                  <li>
                    {{ destinationRangesValue }}
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>

            <div class="px-2">
              <span class="title"> Source Tags:</span>
              <div v-if="value.source_tags.length !== 0">
                <ul
                  v-for="(sourceTagsValue, sourceTagsKey) in value.source_tags"
                >
                  <li>
                    {{ sourceTagsValue }}
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>

            <div class="px-2">
              <span class="title"> Target Tags:</span>
              <div v-if="value.target_tags.length !== 0">
                <ul
                  v-for="(targetTagsValue, targetTagsKey) in value.target_tags"
                >
                  <li>
                    {{ targetTagsValue }}
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Allowed Traffic</h4>
            <div v-if="Object.keys(value.allowed_traffic).length !== 0">
              <ul
                v-for="(
                  destinationRangesValue, destinationRangesKey
                ) in value.allowed_traffic"
              >
                <li>
                  {{ destinationRangesKey }}
                </li>
                <ul v-for="(rangeValue, rangeKey) in destinationRangesValue">
                  <li>
                    {{ rangeValue }}
                  </li>
                </ul>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'networks'">
        <span v-for="(value, key) in data.networks">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">ID: </span>
                <span class="description">
                  {{ value.id ? value.id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project_id ? value.project_id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Description:</span>
                <span class="description">
                  {{ value.description ? value.description : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Creation Date:</span>
                <span class="description">
                  {{
                    value.creation_timestamp ? value.creation_timestamp : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Legacy Mode:</span>
                <span class="description">
                  {{ value.legacy_mode ? value.legacy_mode : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Firewall Rules</h4>
            <div v-if="value.firewalls.length !== 0">
              <ul v-for="(firewallsValue, firewallsKey) in value.firewalls">
                <li>{{ firewallsValue }}</li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Compute Engine Instances</h4>
            <div v-if="value.instances.length !== 0">
              <ul v-for="(instancesValue, instancesKey) in value.instances">
                <li>{{ instancesValue.instance_zone }}</li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'subnetwork'">
        <span v-for="(value1, key1) in data.regions">
          <span v-for="(value, key) in value1.subnetworks">
            <div>
              <h4 class="topic-name">
                {{ value.name }}
              </h4>
            </div>

            <div>
              <h4 class="headline">Information</h4>
              <div>
                <div class="px-2">
                  <span class="title"> Name: </span>
                  <span class="description">
                    {{ value.name ? value.name : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> ID: </span>
                  <span class="description">
                    {{ value.id ? value.id : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Project ID: </span>
                  <span class="description">
                    {{ value.project_id ? value.project_id : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Region: </span>
                  <span class="description">
                    {{ value.region ? value.region : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Creation Date:</span>
                  <span class="description">
                    {{
                      value.creation_timestamp
                        ? value.creation_timestamp
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> IP Range: </span>
                  <span class="description">
                    {{ value.ip_range ? value.ip_range : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Gateway Address:</span>
                  <span class="description">
                    {{
                      value.gateway_address ? value.gateway_address : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Private Google Access:</span>
                  <span class="description">
                    {{
                      value.private_ip_google_access
                        ? value.private_ip_google_access
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> VPC Flow Logs:</span>
                  <span class="description">
                    {{
                      value.flowlogs_enabled ? value.flowlogs_enabled : "None"
                    }}</span
                  >
                </div>
              </div>
            </div>
            <hr />

            <div>
              <h4 class="headline">Compute Engine Instances</h4>
              <div v-if="value.instances.length !== 0">
                <ul v-for="(instancesValue, instancesKey) in value.instances">
                  <li>
                    {{ instancesValue.instance_id }} :
                    {{ instancesValue.instance_zone }}
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>
          </span>
        </span>
      </div>

      <div v-if="databaseName == 'snapshots'">
        <span v-for="(value, key) in data.snapshots">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Description:</span>
                <span class="description">
                  {{ value.description ? value.description : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Creation Date:</span>
                <span class="description">
                  {{
                    value.creation_timestamp ? value.creation_timestamp : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Status: </span>
                <span class="description">
                  {{ value.status ? value.status : "None" }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'instances'">
        <span v-for="(value1, key1) in data.zones">
          <span v-for="(value, key) in value1.instances">
            <div>
              <h4 class="topic-name">
                {{ value.name }}
              </h4>
            </div>

            <div>
              <h4 class="headline">Information</h4>
              <div>
                <div class="px-2">
                  <span class="title"> Instance Name: </span>
                  <span class="description">
                    {{ value.name ? value.name : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Project ID: </span>
                  <span class="description">
                    {{ value.project_id ? value.project_id : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Description:</span>
                  <span class="description">
                    {{ value.description ? value.description : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Creation Date:</span>
                  <span class="description">
                    {{
                      value.creation_timestamp
                        ? value.creation_timestamp
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Status: </span>
                  <span class="description">
                    {{ value.status ? value.status : "None" }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Deletion Protection:</span>
                  <span class="description">
                    {{
                      value.deletion_protection_enabled
                        ? value.deletion_protection_enabled
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Block Project SSH Keys:</span>
                  <span class="description">
                    {{
                      value.block_project_ssh_keys_enabled
                        ? value.block_project_ssh_keys_enabled
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> IP Forwarding:</span>
                  <span class="description">
                    {{
                      value.ip_forwarding_enabled
                        ? value.ip_forwarding_enabled
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> OS Login:</span>
                  <span class="description">
                    {{
                      value.oslogin_enabled ? value.oslogin_enabled : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Serial Port Connection:</span>
                  <span class="description">
                    {{
                      value.serial_port_enabled
                        ? value.serial_port_enabled
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Public IP Addresses:</span>
                  <span class="description">
                    {{
                      value.public_ip_addresses
                        ? value.public_ip_addresses
                        : "None"
                    }}</span
                  >
                </div>
                <div class="px-2">
                  <span class="title"> Shielded VM:</span>
                  <span class="description">
                    {{
                      value.shielded_enable ? value.shielded_enable : "None"
                    }}</span
                  >
                </div>

                <div class="px-2">
                  <span class="title"> Tags:</span>
                  <div v-if="Object.keys(value.tags).length !== 0">
                    <ul v-for="(value, key) in value.tags">
                      <li>
                        {{ key }}
                      </li>
                      <ul>
                        <li>
                          {{ value }}
                        </li>
                      </ul>
                    </ul>
                  </div>
                  <div v-else>
                    <ul>
                      <li>None</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div>
              <h4 class="headline">Network Interfaces</h4>
              <div v-if="value.network_interfaces.length !== 0">
                <ul
                  v-for="(
                    networkInterfacesValue, networkInterfacesKey
                  ) in value.network_interfaces"
                >
                  <li>
                    {{ networkInterfacesValue.name }}
                  </li>
                  <ul>
                    <li>
                      <span class="title"> IP:</span>
                      <span class="description">
                        {{
                          networkInterfacesValue.networkIP
                            ? networkInterfacesValue.networkIP
                            : "None"
                        }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Network:</span>
                      <span class="description">
                        {{
                          networkInterfacesValue.network_id
                            ? networkInterfacesValue.network_id
                            : "None"
                        }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Subnetwork:</span>
                      <span class="description">
                        {{
                          networkInterfacesValue.subnetwork_region
                            ? networkInterfacesValue.subnetwork_region
                            : "None"
                        }}</span
                      >
                    </li>
                  </ul>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>
            <hr />

            <div>
              <h4 class="headline">Identity & API Access</h4>
              <div class="px-2">
                <span class="title"> Service Account:</span>
                <span class="description">
                  {{
                    value.service_account ? value.service_account : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Access Scopes: </span>
                <div v-if="value.access_scopes.length !== 0">
                  <ul
                    v-for="(
                      accessScopesValue, accessScopesKey
                    ) in value.access_scopes"
                  >
                    <li>{{ accessScopesValue }}</li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>None</li>
                  </ul>
                </div>
              </div>

              <div class="px-2">
                <span class="title">
                  Default Service Account with Full Access to All Cloud
                  APIs:</span
                >
                <span class="description">
                  {{
                    value.full_access_apis ? value.full_access_apis : "None"
                  }}</span
                >
              </div>
            </div>
            <hr />

            <div>
              <h4 class="headline">Disks</h4>
              <div v-if="Object.keys(value.disks).length !== 0">
                <ul v-for="(diskValue, diskKey) in value.disks">
                  <li>
                    {{ diskValue.source_device_name }}
                  </li>
                  <ul>
                    <li>
                      <span class="title"> Bootable:</span>
                      <span class="description">
                        {{
                          diskValue.bootable ? diskValue.bootable : "None"
                        }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Type: </span>
                      <span class="description">
                        {{ diskValue.type ? diskValue.type : "None" }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Mode: </span>
                      <span class="description">
                        {{ diskValue.mode ? diskValue.mode : "None" }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Latest snapshot:</span>
                      <span class="description">
                        {{
                          diskValue.latest_snapshot
                            ? diskValue.latest_snapshot
                            : "None"
                        }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Customer Supplied Encryption:</span>
                      <span class="description">
                        {{
                          diskValue.encrypted_with_csek
                            ? diskValue.encrypted_with_csek
                            : "None"
                        }}
                      </span>
                    </li>
                  </ul>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li>None</li>
                </ul>
              </div>
            </div>
            <hr />
          </span>
        </span>
      </div>

      <!-- dns -->
      <div v-if="databaseName == 'cloud_dns'">
        <span v-for="(value, key) in data.managed_zones">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> ID: </span>
                <span class="description">
                  {{ value.id ? value.id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Description:</span>
                <span class="description">
                  {{ value.description ? value.description : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Creation Date:</span>
                <span class="description">
                  {{
                    value.creation_timestamp ? value.creation_timestamp : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> DNSSEC:</span>
                <span class="description">
                  {{
                    value.dnssec_enabled ? value.dnssec_enabled : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Visibility: </span>
                <span class="description">
                  {{ value.visibility ? value.visibility : "None" }}</span
                >
              </div>

              <b-card class="project-data">
                <h5>DNSSEC Keys:</h5>
                <div v-if="value.dnssec_keys !== null">
                  <ul v-for="(value, key) in value.dnssec_keys">
                    <li>
                      {{ key }}
                      <ul>
                        <li>{{ value.key_algorithm }}</li>
                        <li>{{ value.key_type }}</li>
                        <li>{{ value.length }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>None</li>
                  </ul>
                </div>
              </b-card>
            </div>
          </div>
        </span>
      </div>

      <!-- functions -->
      <div v-if="databaseName == 'functions'">
        <span v-for="(value, key) in data.functions_v1">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Status: </span>
                <span class="description">
                  {{ value.status ? value.status : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Update Time:</span>
                <span class="description">
                  {{ value.update_time ? value.update_time : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Version: </span>
                <span class="description">
                  {{ value.version_id ? value.version_id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Runtime: </span>
                <span class="description">
                  {{ value.runtime ? value.runtime : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Memory: </span>
                <span class="description">
                  {{ value.memory ? value.memory : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Max Instances:</span>
                <span class="description">
                  {{ value.max_instances ? value.max_instances : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Docker Registry:</span>
                <span class="description">
                  {{
                    value.docker_registry ? value.docker_registry : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Service Account:</span>
                <span class="description">
                  {{
                    value.service_account ? value.service_account : "None"
                  }}</span
                >
              </div>
              <div>
                <h4 class="headline">Environment Variables</h4>
                <div v-if="value.environment_variables.length !== 0">
                  <ul
                    v-for="(
                      environmentVariablesValue, environmentVariablesKey
                    ) in value.environment_variables"
                  >
                    <li>
                      {{ environmentVariablesKey }} :
                      {{ environmentVariablesValue }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>None</li>
                  </ul>
                </div>
              </div>
              <div>
                <h4 class="headline">
                  Environment Variables Secrets (Potential)
                </h4>
                <div v-if="value.environment_variables_secrets.length !== 0">
                  <ul
                    v-for="(
                      environmentVariablesSecretsValue,
                      environmentVariablesSecretsKey
                    ) in value.environment_variables_secrets"
                  >
                    <li>{{ environmentVariablesSecretsValue }}</li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li>None</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Trigger</h4>
            <div>
              <div class="px-2">
                <span class="title"> URL: </span>
                <span class="description">
                  {{ value.url ? value.url : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Security Level:</span>
                <span class="description">
                  {{
                    value.security_level ? value.security_level : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Ingress Settings:</span>
                <span class="description">
                  {{
                    value.ingress_settings ? value.ingress_settings : "None"
                  }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Bindings</h4>
            <div v-if="value.bindings.length !== 0">
              <ul v-for="(value, key) in value.bindings">
                <li>
                  {{ value.role }}
                </li>
                <ul v-for="(value, key) in value.members">
                  <li>
                    {{ value }}
                  </li>
                </ul>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <!-- iam -->
      <div v-if="databaseName == 'bindings'">
        <span v-for="(value, key) in data.bindings">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Title: </span>
                <span class="description">
                  {{ value.title ? value.title : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Description:</span>
                <span class="description">
                  {{ value.description ? value.description : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ projectName ? projectName : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Custom Role:</span>
                <span class="description">
                  {{ value.custom_role ? value.custom_role : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Permissions</h4>
            <div v-if="key !== expand">
              <div v-if="value.permissions">
                <div v-for="(Permission, key) in value.permissions">
                  <div class="px-2" v-if="key <= 5">
                    {{ key }} : {{ Permission }}
                  </div>
                </div>
              </div>
            </div>
            <b-collapse id="collapse-1" v-if="key == expand">
              <div v-if="value.permissions">
                <div v-for="(Permission, key) in value.permissions">
                  <div class="px-2">{{ key }} : {{ Permission }}</div>
                </div>
              </div>

              <div v-else>None</div>
            </b-collapse>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="key !== expand"
                v-b-toggle.collapse-1
                size="sm"
                variant="primary"
                @click="showPermissions(value.id)"
                >Show More</b-button
              >
              <b-button
                v-if="key == expand"
                v-b-toggle.collapse-1
                size="sm"
                variant="primary"
                @click="showPermissions(value.id)"
                >Show Less</b-button
              >
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Bindings</h4>
            <h5 class="headline">Attached Users:</h5>
            <div v-if="value.members.users.length !== 0">
              <ul class="px-4" v-for="user in value.members.users">
                <li>
                  {{ user }}
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
            <h5 class="headline">Attached Groups:</h5>
            <div v-if="value.members.groups.length !== 0">
              <ul class="px-4" v-for="user in value.members.groups">
                <li>
                  {{ user }}
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
            <h5 class="headline">Attached Service Accounts:</h5>
            <div v-if="value.members.service_accounts.length !== 0">
              <ul class="px-4" v-for="user in value.members.service_accounts">
                <li>
                  {{ user }}
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
            <h5 class="headline">Attached Domains:</h5>
            <div v-if="value.members.domains.length !== 0">
              <ul class="px-4" v-for="user in value.members.domains">
                <li>
                  {{ user }}
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'project'">
        <span v-for="(value, key) in data.bindings_separation_duties">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title">
                  Separation of duties enforced for service account related
                  roles:</span
                >
                <span class="description">
                  {{
                    value.account_separation_duties
                      ? value.account_separation_duties
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Separation of duties enforced for KMS related roles:</span
                >
                <span class="description">
                  {{
                    value.kms_separation_duties
                      ? value.kms_separation_duties
                      : "None"
                  }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'domains'">
        <span v-for="(value, key) in data.domains">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Domain: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Bindings</h4>
            <div v-if="value.roles.length !== 0">
              <ul v-for="(roleValue, roleKey) in value.roles">
                <li>{{ roleValue }}</li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'groups'">
        <span v-for="(value, key) in data.groups">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Group: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Bindings</h4>
            <div v-if="value.roles.length !== 0">
              <ul v-for="(roleValue, roleKey) in value.roles">
                <li>{{ roleValue }}</li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'service_accounts'">
        <span v-for="(value, key) in data.service_accounts">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> ID: </span>
                <span class="description">
                  {{ value.id ? value.id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project_id ? value.project_id : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Email: </span>
                <span class="description">{{
                  value.email ? value.email : "None"
                }}</span>
              </div>
              <div class="px-2">
                <span class="title"> Display Name:</span>
                <span class="description">
                  {{ value.display_name ? value.display_name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Default Service Account:</span>
                <span class="description">
                  {{
                    value.default_service_account
                      ? value.default_service_account
                      : "None"
                  }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Keys</h4>
            <ul v-for="(value, key) in value.keys">
              <li>
                <span class="title"> key: </span>
                <span class="description"> {{ key }}</span>
                <ul>
                  <li>
                    <span class="title"> Key Type: </span>
                    <span class="description">
                      {{ value.key_type ? value.key_type : "None" }}</span
                    >
                  </li>
                  <li>
                    <span class="title"> Key Algorithm:</span>
                    <span class="description">
                      {{
                        value.key_algorithm ? value.key_algorithm : "None"
                      }}</span
                    >
                  </li>
                  <li>
                    <span class="title"> Valid Before:</span>
                    <span class="description">
                      {{
                        value.valid_before ? value.valid_before : "None"
                      }}</span
                    >
                  </li>
                  <li>
                    <span class="title"> Valid After:</span>
                    <span class="description">
                      {{ value.valid_after ? value.valid_after : "None" }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <hr />

          <div>
            <h4 class="headline">Service Account Users</h4>
            <div v-if="Object.keys(value.bindings).length !== 0">
              <ul v-for="(value, key) in value.bindings">
                <li>
                  {{ value.role }}
                </li>
                <ul v-for="(value, key) in value.members">
                  <li>
                    {{ value }}
                  </li>
                </ul>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'users'">
        <span v-for="(value, key) in data.users">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> User: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Bindings</h4>
            <div v-if="value.roles.length !== 0">
              <ul v-for="(roleValue, roleKey) in value.roles">
                <li>{{ roleValue }}</li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
        </span>
      </div>

      <!-- kms -->
      <div v-if="databaseName == 'kms_bindings' || databaseName == 'keys'">
        <span v-for="(value, key) in data.keyrings">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Location: </span>
                <span class="description">
                  {{ value.location ? value.location : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Keys</h4>
            <ul v-for="(keysValue, keysKey) in value.keys">
              <li>
                {{ keysKey }}
              </li>
              <ul>
                <li>
                  <span class="title"> State: </span>
                  <span class="description">
                    {{ keysValue.state ? keysValue.state : "None" }}</span
                  >
                </li>
                <li>
                  <span class="title"> Protection Level:</span>
                  <span class="description">
                    {{
                      keysValue.protection_level
                        ? keysValue.protection_level
                        : "None"
                    }}</span
                  >
                </li>
                <li>
                  <span class="title"> Algorithm:</span>
                  <span class="description">
                    {{
                      keysValue.algorithm ? keysValue.algorithm : "None"
                    }}</span
                  >
                </li>
                <li>
                  <span class="title"> Purpose: </span>
                  <span class="description">
                    {{ keysValue.purpose ? keysValue.purpose : "None" }}</span
                  >
                </li>
                <li>
                  <span class="title"> Creation Date:</span>
                  <span class="description">
                    {{
                      keysValue.creation_datetime
                        ? keysValue.creation_datetime
                        : "None"
                    }}</span
                  >
                </li>
                <li>
                  <span class="title"> Rotation Period:</span>
                  <span class="description">
                    {{
                      keysValue.rotation_period
                        ? keysValue.rotation_period
                        : "None"
                    }}</span
                  >
                </li>
                <li>
                  <span class="title"> Next Rotation Date:</span>
                  <span class="description">
                    {{
                      keysValue.next_rotation_datetime
                        ? keysValue.next_rotation_datetime
                        : "None"
                    }}</span
                  >
                </li>
                <li>
                  <span class="title"> Days Until Next Rotation:</span>
                  <span class="description">
                    {{
                      keysValue.next_rotation_time_days
                        ? keysValue.next_rotation_time_days
                        : "None"
                    }}</span
                  >
                </li>
                <li>Bindings</li>
                <ul v-for="(kmsValue, kmsKey) in kms_iam_policy">
                  <li>{{ kmsValue.name }}</li>
                  <ul>
                    <li>
                      <span class="title"> Title: </span>
                      <span class="description">
                        {{ kmsValue.title ? kmsValue.title : "None" }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Description:</span>
                      <span class="description">
                        {{
                          kmsValue.description ? kmsValue.description : "None"
                        }}</span
                      >
                    </li>
                    <li>
                      <span class="title"> Custom Role:</span>
                      <span class="description">
                        {{
                          kmsValue.custom_role ? kmsValue.custom_role : "None"
                        }}</span
                      >
                    </li>
                    <li>
                      <span class="title">
                        Not anonymously or publicly accessible:</span
                      >
                      <span class="description">
                        {{
                          kmsValue.anonymous_public_accessible
                            ? kmsValue.anonymous_public_accessible
                            : "None"
                        }}</span
                      >
                    </li>
                  </ul>
                </ul>
              </ul>
            </ul>
          </div>
        </span>
      </div>

      <!-- kubernetesengine -->
      <div v-if="databaseName == 'clusters'">
        <span v-for="(value, key) in data.clusters">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Location: </span>
                <span class="description">
                  {{ value.location ? value.location : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Type: </span>
                <span class="description">
                  {{ value.type ? value.type : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Status: </span>
                <span class="description">
                  {{ value.status ? value.status : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Image Type: </span>
                <span class="description">
                  {{ value.image_type ? value.image_type : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Release Channel:</span>
                <span class="description">
                  {{
                    value.release_channel ? value.release_channel : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Basic Authentication:</span>
                <span class="description">
                  {{
                    value.basic_authentication_enabled
                      ? value.basic_authentication_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Client Certificate Authentication:</span>
                <span class="description">
                  {{
                    value.client_certificate_enabled
                      ? value.client_certificate_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Dashboard:</span>
                <span class="description">
                  {{
                    value.dashboard_status ? value.dashboard_status : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Legacy Authorization (ABAC):</span>
                <span class="description">
                  {{
                    value.legacy_abac_enabled
                      ? value.legacy_abac_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Pod Security Policy:</span>
                <span class="description">
                  {{
                    value.pod_security_policy_enabled
                      ? value.pod_security_policy_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Network Policy:</span>
                <span class="description">
                  {{
                    value.network_policy_enabled
                      ? value.network_policy_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Service Account:</span>
                <span class="description">
                  {{
                    value.service_account ? value.service_account : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Workload Identity:</span>
                <span class="description">
                  {{
                    value.workload_identity_enabled
                      ? value.workload_identity_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Private Google Access:</span>
                <span class="description">
                  {{
                    value.private_ip_google_access_enabled
                      ? value.private_ip_google_access_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Alias IP:</span>
                <span class="description">
                  {{
                    value.alias_ip_enabled ? value.alias_ip_enabled : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Endpoint: </span>
                <span class="description">
                  {{ value.endpoint ? value.endpoint : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Private Endpoint:</span>
                <span class="description">
                  {{
                    value.private_endpoint_enabled
                      ? value.private_endpoint_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Private Endpoint IP:</span>
                <span class="description">
                  {{
                    value.private_endpoint ? value.private_endpoint : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Public Endpoint IP:</span>
                <span class="description">
                  {{
                    value.public_endpoint ? value.public_endpoint : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Binary Authorization:</span>
                <span class="description">
                  {{
                    value.binary_authorization_enabled
                      ? value.binary_authorization_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Shielded Nodes:</span>
                <span class="description">
                  {{
                    value.shielded_nodes_enabled
                      ? value.shielded_nodes_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Application-Layer Secrets Encryption:</span
                >
                <span class="description">
                  {{
                    value.application_layer_encryption_enabled
                      ? value.application_layer_encryption_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Stackdriver Logging:</span>
                <span class="description">
                  {{
                    value.logging_enabled ? value.logging_enabled : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Stackdriver Monitoring:</span>
                <span class="description">
                  {{
                    value.monitoring_enabled ? value.monitoring_enabled : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Labels: </span>
                <span class="description">
                  {{ value.labels ? value.labels : "None" }}</span
                >
              </div>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Scopes</h4>
            <div v-if="value.scopes.length !== 0">
              <ul v-for="(value, key) in value.scopes">
                <li>
                  {{ value }}
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Master Authorized Networks</h4>
            <div v-if="value.master_authorized_networks_config.cidrBlocks">
              <ul
                v-for="(value, key) in value.master_authorized_networks_config
                  .cidrBlocks"
              >
                <li>{{ value.displayName }}: {{ value.cidrBlock }}</li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>None</li>
              </ul>
            </div>
          </div>
          <hr />

          <div>
            <h4 class="headline">Node pools</h4>
            <div class="px-2">
              <span class="title"> Private Nodes:</span>
              <span class="description">
                {{
                  value.private_nodes_enabled
                    ? value.private_nodes_enabled
                    : "None"
                }}</span
              >
            </div>
            <div class="px-2">
              <span class="title"> Metadata Server:</span>
              <span class="description">
                {{
                  value.metadata_server_enabled
                    ? value.metadata_server_enabled
                    : "None"
                }}</span
              >
            </div>
            <div class="px-2" v-for="(value, key) in value.node_pools">
              {{ key }}
              <div class="px-2">
                <span class="title"> Integrity Monitoring:</span>
                <span class="description">
                  {{
                    value.integrity_monitoring_enabled
                      ? value.integrity_monitoring_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Secure Boot:</span>
                <span class="description">
                  {{
                    value.secure_boot_enabled
                      ? value.secure_boot_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Legacy Metadata Endpoints:</span>
                <span class="description">
                  {{
                    value.legacy_metadata_endpoints_enabled
                      ? value.legacy_metadata_endpoints_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Automatic Node Upgrades:</span>
                <span class="description">
                  {{
                    value.auto_upgrade_enabled
                      ? value.auto_upgrade_enabled
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Automatic Node Repair:</span>
                <span class="description">
                  {{
                    value.auto_repair_enabled
                      ? value.auto_repair_enabled
                      : "None"
                  }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <!-- stackdriverlogging -->
      <div v-if="databaseName == 'logging_configurations'">
        <span v-for="(value, key) in data.logging_metrics">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title">
                  Project Ownership Assignment/Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.project_ownership_assignments
                      ? value.project_ownership_assignments
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Audit Configuration Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.audit_config_change
                      ? value.audit_config_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Custom Role Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.custom_role_change ? value.custom_role_change : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  VPC Network Firewall Rule Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.vpc_network_firewall_rule_change
                      ? value.vpc_network_firewall_rule_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  VPC Network Route Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.vpc_network_route_change
                      ? value.vpc_network_route_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  VPC Network Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.vpc_network_change ? value.vpc_network_change : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Cloud Storage IAM Permission Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.cloud_storage_iam_permission_change
                      ? value.cloud_storage_iam_permission_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  SQL Instance Configuration Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.sql_instance_conf_change
                      ? value.sql_instance_conf_change
                      : "None"
                  }}
                </span>
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'metrics'">
        <span v-for="(value, key) in data.metrics">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Sink Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Filter: </span>
                <span class="description">
                  {{ value.filter ? value.filter : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Destination:</span>
                <span class="description">
                  {{ value.destination ? value.destination : "None" }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'sinks'">
        <span v-for="(value, key) in data.sinks">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Sink Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Project ID: </span>
                <span class="description">
                  {{ value.project ? value.project : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Filter: </span>
                <span class="description">
                  {{ value.filter ? value.filter : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Destination:</span>
                <span class="description">
                  {{ value.destination ? value.destination : "None" }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <!-- stackdrivermonitoring -->
      <div v-if="databaseName == 'alert_policies'">
        <span v-for="(value, key) in data.alert_policies">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.name ? value.name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Creation Record:</span>
                <span class="description">
                  {{
                    value.creation_record ? value.creation_record : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Mutation Record:</span>
                <span class="description">
                  {{
                    value.mutation_record ? value.mutation_record : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Conditions: </span>
                <span class="description">
                  {{ value.conditions ? value.conditions : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Combiner: </span>
                <span class="description">
                  {{ value.combiner ? value.combiner : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Enabled: </span>
                <span class="description">
                  {{ value.enabled ? value.enabled : "None" }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'monitoring_alerts'">
        <span v-for="(value, key) in data.monitoring_alert_policies">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title">
                  Project Ownership Assignment/Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.project_ownership_assignments
                      ? value.project_ownership_assignments
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Audit Configuration Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.audit_config_change
                      ? value.audit_config_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Custom Role Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.custom_role_change ? value.custom_role_change : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  VPC Network Firewall Rule Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.vpc_network_firewall_rule_change
                      ? value.vpc_network_firewall_rule_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  VPC Network Route Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.vpc_network_route_change
                      ? value.vpc_network_route_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  VPC Network Changes Log Metric Filter Exist:</span
                >
                <span class="description">
                  {{
                    value.vpc_network_change ? value.vpc_network_change : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  Cloud Storage IAM Permission Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.cloud_storage_iam_permission_change
                      ? value.cloud_storage_iam_permission_change
                      : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">
                  SQL Instance Configuration Changes Log Metric Filter
                  Exist:</span
                >
                <span class="description">
                  {{
                    value.sql_instance_conf_change
                      ? value.sql_instance_conf_change
                      : "None"
                  }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="databaseName == 'uptime_checks'">
        <span v-for="(value, key) in data.uptime_checks">
          <div>
            <h4 class="topic-name">
              {{ value.name }}
            </h4>
          </div>

          <div>
            <h4 class="headline">Information</h4>
            <div>
              <div class="px-2">
                <span class="title"> Name: </span>
                <span class="description">
                  {{ value.display_name ? value.display_name : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Monitored Resource:</span>
                <span class="description">
                  {{
                    value.monitored_resource ? value.monitored_resource : "None"
                  }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> HTTP Check: </span>
                <span class="description">
                  {{ value.http_check ? value.http_check : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title"> Period: </span>
                <span class="description">
                  {{ value.period ? value.period : "None" }}</span
                >
              </div>
              <div class="px-2">
                <span class="title">Timeout: </span>
                <span class="description">
                  {{ value.timeout ? value.timeout : "None" }}</span
                >
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BFormCheckbox,
  BCollapse,
  VBToggle,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import router from "@/router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTable,
    BPagination,
    BFormCheckbox,
    BCollapse,
    VBToggle,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      scoutsuiteResult: null,
      projectsData: [],
      showAllData: false,
      projectName: "",
      htmldata: [],
      databaseName: "",
      expand: 0,
      serviceName: "",
      servicesInfromation: [],
      securityanalysisData: JSON.parse(
        localStorage.getItem("securityanalysisData")
      ),
      securityanalysisDatabaseNameIndex: JSON.parse(
        localStorage.getItem("securityanalysisDatabaseNameIndex")
      ),
      securityanalysisRoleName: "",
    };
  },
  beforeMount() {
    this.getRoles();
  },
  watch: {
    "$store.state.app.userData.default_region_id": {
      handler() {
        router.push({ name: "app-scoutsuite" });
      },
    },
  },
  methods: {
    showPermissions(id) {
      if (id == this.expand) {
        this.expand = 0;
      } else {
        this.expand = id;
      }
    },
    getdata(dataObj) {
      //for <ul> and <li> tag for nested objects
      if (Object.keys(dataObj) && typeof dataObj == "object") {
        for (const [key, value] of Object.entries(dataObj)) {
          this.htmldata.push(`<ul> ${key} </ul>`);
          this.getdata(value);
        }
        return this.htmldata.toString().split(",").join("");
      } else {
        return;
      }
    },
    getCaptitalKey(key) {
      const arrKey = key.split("_");
      for (let i = 0; i < arrKey.length; i++) {
        arrKey[i] = arrKey[i].charAt(0).toUpperCase() + arrKey[i].slice(1);
      }
      const listKey = arrKey.join(" ");
      return listKey;
    },
    getProjectKey(data) {
      let projectKey = "";
      projectKey = Object.keys(data)[0];
      return data[projectKey];
    },
    getRoles() {
      this.scoutsuiteResult = JSON.parse(
        sessionStorage.getItem("scoutsuiteResult")
      );
      for (const [key, value] of Object.entries(
        this.scoutsuiteResult.services
      )) {
        this.databaseName = router.currentRoute.params.id.split(":")[0];
        if (router.currentRoute.params.id.split(":")[1].split("-")[0] == key) {
          if (value.projects) {
            for (const [key1, value1] of Object.entries(value.projects)) {
              this.projectName = key1;
              let data = [];
              data.push(value1);
              this.projectsData = data;
            }
          }
          if (value.findings) {
            for (const [key1, value1] of Object.entries(value.findings)) {
              if (router.currentRoute.params.id.split(":")[1] == key1) {
                this.servicesInfromation.push(value1);
              }
            }
          }
          const objFinding = Object.keys(value?.findings)[0];
          this.serviceName = objFinding
            ? value.findings[objFinding].service
            : key;
        }
      }

      const RoleName = router.currentRoute.params.id
        .split(":")[1]
        .replaceAll("-", " ")
        .split(" ");
      for (let i = 0; i < RoleName.length; i++) {
        RoleName[i] =
          RoleName[i].charAt(0).toUpperCase() + RoleName[i].slice(1);
      }
      this.securityanalysisRoleName = RoleName.join(" ");
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.topic-name {
  background-color: $primary;
  padding: 1rem;
  color: $white;
  border-top-right-radius: 0.428rem;
  border-top-left-radius: 0.428rem;
}
.headline {
  padding: 0.5rem;
}
.title {
  font-weight: 500;
}
.project-data {
  margin-bottom: 1rem;
  border: 1px solid $border-color;
  border-radius: 0;
}
</style>
